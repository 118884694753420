import ProductCard from '../../../Components/Advertisements/ProductCard';

const Waiting = () => (
    <>
        Waiting
        <ProductCard />
    </>

);

export default Waiting;
