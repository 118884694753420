import { Autocomplete, ListSubheader, TextField } from "@mui/material";

interface Category {
    title: string;
    subcategories: string[];
}

const categories: Category[] = [
    {
        title: "Краса та здоров'я",
        subcategories: [
            'Парфумерія',
            'Декоративна косметика',
            'Догляд за обличчям',
            'Догляд за тілом',
            'Догляд за волоссям',
            'Манікюр та педикюр',
            'Засоби гігієни',
            'Органічна косметика',
            'Професійна косметика',
            'Засоби для гоління',
            'Сонцезахисні засоби',
            'Засоби для ванни',
            'Ароматерапія',
            'Дієтичні добавки',
            'Вітаміни',
            'Медичні прилади',
        ],
    },
    {
        title: 'Дім і сад',
        subcategories: [
            'Меблі для вітальні',
            'Кухонні меблі',
            'Меблі для спальні',
            'Офісні меблі',
            'Садові меблі',
            'Освітлення',
            'Постільні приналежності',
            'Предмети інтер`єру',
            'Килими та підлогові покриття',
            'Кухонне начиння',
            'Домашній текстиль',
            'Інструменти для саду',
            'Рослини та квіти',
            'Городні товари',
            'Декор для саду',
            'Системи поливу',
        ],
    },
    {
        title: 'Одяг та взуття',
        subcategories: [
            'Жіночі сукні',
            'Чоловічі костюми',
            'Джинси',
            'Спортивний одяг',
            'Верхній одяг',
            'Нижня білизна',
            'Взуття для чоловіків',
            'Взуття для жінок',
            'Дитяче взуття',
            'Аксесуари',
            'Сумки',
            'Головні убори',
            'Шарфи',
            'Рукавички',
            'Пояси',
            'Окуляри',
        ],
    },
    {
        title: 'Техніка та електроніка',
        subcategories: [
            'Смартфони',
            'Планшети',
            'Ноутбуки',
            'Настільні комп`ютери',
            'Телевізори',
            'Аудіотехніка',
            'Фотоапарати',
            'Відеокамери',
            'Ігрові консолі',
            'Розумні годинники',
            'Побутова техніка',
            'Кліматичне обладнання',
            'Кухонна техніка',
            'Пилососи',
            'Прилади для догляду за одягом',
            'Електроінструменти',
        ],
    },
    {
        title: 'Товари для дітей',
        subcategories: [
            'Іграшки для малюків',
            'Розвиваючі іграшки',
            'Конструктори',
            'Ігрові комплекси',
            'Настільні ігри',
            'Пазли',
            'Дитячі книжки',
            'Товари для новонароджених',
            'Дитячий одяг',
            'Дитяче взуття',
            'Дитячі коляски',
            'Автокрісла',
            'Шкільні товари',
            'Дитячі меблі',
            'Велосипеди',
            'Спортивні товари для дітей',
        ],
    },
    {
        title: 'Спорт і відпочинок',
        subcategories: [
            'Тренажери',
            'Велосипеди',
            'Роликові ковзани',
            'Скейтборди',
            'Лижі та сноуборди',
            'Туристичне спорядження',
            'Намети',
            'Спальні мішки',
            'Рюкзаки',
            'Фітнес-браслети',
            'Спортивне харчування',
            'Бойові мистецтва',
            'Водний спорт',
            'Гольф',
            'Теніс',
            'Футбол',
        ],
    },
    {
        title: 'Книги',
        subcategories: [
            'Сучасна проза',
            'Класична література',
            'Детективи',
            'Фантастика',
            'Фентезі',
            'Бізнес-книги',
            'Саморозвиток',
            'Психологія',
            'Дитяча література',
            'Науково-популярна література',
            'Історія',
            'Мистецтво',
            'Релігія та езотерика',
            'Кулінарія',
            'Мовні курси',
            'Енциклопедії',
        ],
    },
    {
        title: 'Аксесуари та прикраси',
        subcategories: [
            'Годинники',
            'Ювелірні вироби',
            'Біжутерія',
            'Сумки',
            'Гаманці',
            'Шарфи та хустки',
            'Ремені',
            'Окуляри від сонця',
            'Запонки',
            'Краватки',
            'Шапки',
            'Рукавички',
            'Зонт',
            'Візитниці',
            'Ключниці',
            'Чохли для техніки',
        ],
    },
];

const options = categories.flatMap((category) => category.subcategories.map((subcategory) => ({
    group: category.title,
    title: subcategory,
})));

const CategorySelect = () => (
    <Autocomplete
        id='grouped-demo'
        options={options.sort((a, b) => -b.group.localeCompare(a.group))}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
            <TextField {...params} placeholder='Select a category' />
        )}
        renderGroup={(params) => [
            <ListSubheader
                key={params.key}
                component='div'
                style={{
                    backgroundColor: 'white',
                    top: '-8px',
                    padding: '4px 10px',
                }}
            >
                {params.group}
            </ListSubheader>,
            params.children,
        ]}
        sx={{ width: '40%' }}
    />
)

export default CategorySelect;