import ProductCard from '../../../Components/Advertisements/ProductCard';

const Inactive = () => (
    <>
        InActive
        <ProductCard />
    </>

);

export default Inactive;
