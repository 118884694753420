import ProductCard from '../../../Components/Advertisements/ProductCard';

const Active = () => (
    <>
        Active
        <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
        </div>
    </>

);

export default Active;
