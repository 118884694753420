import ProductCard from '../../../Components/Advertisements/ProductCard';

const Rejected = () => (
    <>
        Rejected
        <ProductCard />
    </>

);

export default Rejected;
