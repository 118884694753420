import ProductCard from '../../../Components/Advertisements/ProductCard';

const Unpaid = () => (
    <>
        Unpaid
        <ProductCard />
    </>

);

export default Unpaid;
